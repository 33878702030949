<template>
  <ContentContainer v-if="timeline">
    <template
      #title
      v-if="!displayTimelineTaskUUID && !render && timeline != null"
    >
      <div class="row text-left ml-2">
        <template v-if="!timeline.completed">
          <p class="h4" v-if="timeline.in_progress">
            <i class="far fa-check-circle text-primary mr-2"></i>
          </p>
          <p class="h4" v-else>
            <i class="fas fa-ban text-danger mr-2"></i>
          </p>
        </template>
        <template v-else>
          <p class="h4">
            <i class="fas fa-check-circle text-success mr-2"></i>
          </p>
        </template>
        <h4 v-if="timeline.workflow != null">
          {{ timeline.workflow.workflow_name || null }}
        </h4>
      </div>
    </template>
    <template v-else #title>
      <h4 v-if="timeline.workflow">
        {{ timeline.workflow.workflow_name }} timeline
      </h4>
    </template>
    <template
      #header
      v-if="!displayTimelineTaskUUID && !render && timeline != null"
    >
      <div class="timeline-toolbar" v-if="timeline != null">
        <div class="row">
          <template v-if="!timeline.completed">
            <button class="timeline-btn mx-1" @click="resetTimeline">
              <i class="fas fa-sync-alt"></i> Reset Timeline
            </button>
            <button v-if="!timeline.in_progress" class="timeline-btn mx-1" @click="resumeTimeline">
              <i class="fas fa-sync-alt"></i> Resume Timeline
            </button>
            <button
              class="timeline-btn mx-1"
              @click="removeConsumer"
              v-if="!timeline.unsubscribed"
            >
              <i class="fas fa-user-times"></i> Remove Consumer
            </button>
            <button
              class="timeline-btn mx-1"
              @click="abortTimeline"
              v-if="timeline.in_progress"
            >
              <i class="fas fa-ban"></i> Abort Timeline
            </button>
          </template>
          <button
            v-if="consumer"
            class="timeline-btn mx-1"
            @click="toConsumer()"
          >
            <i class="far fa-user-circle h6 p-0 m-0"></i>
            {{ consumer.consumer_email }}
          </button>
        </div>
      </div>
    </template>
    <div class="text-center m-4" v-if="loading">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
    <template v-else>
      <div v-if="!displayTimelineTaskUUID && !render && timeline != null">
        <b-modal
          title="Notify Manager"
          id="contactManager"
          class="w-25 ml-auto text-left"
        >
          <div>Confirm you want to notify the manager</div>
          <template #modal-footer="{ close }">
            <button
              class="btn btn-blue w-25 mt-2 mr-3"
              @click="
                () => {
                  notifyManager();
                  close();
                }
              "
            >
              Submit
            </button>
            <button
              class="btn btn-secondary w-25 mt-2"
              @click="
                notifyTaskID = null;
                close();
              "
            >
              Cancel
            </button>
          </template>
        </b-modal>
        <div
          class="w-100 d-flex"
          v-if="timeline != null && taskGroups.length > 0"
        >
          <small class="text-left">
            Start Date: {{ convertDate(timeline.created_datetime) || null }}
          </small>
        </div>
        <div
          class="timeline-line-container"
          v-if="timeline != null && taskGroups.length > 0"
        >
          <TimelineView :groups="taskGroups" />
        </div>

        <div v-if="timeline != null && timeline.timeline_task_groups">
          <div class="timeline-overview-container" v-if="taskGroups.length > 0">
            <div class="scroll-container">
              <div
                class="draggable-timeline"
                v-for="(group, groupIdx) in taskGroups"
                :key="groupIdx"
              >
                <div class="col w-100">
                  <b-card
                    class="timeline-card"
                    v-for="(task, taskIdx) in group.tasks"
                    :key="taskIdx"
                  >
                    <p class="font-weight-bold">
                      {{ task.task_name }}
                    </p>
                    <p class="font-weight-bold">
                      <template v-if="task.timeline_task_manager_id"
                        >{{ getManager(task.timeline_task_manager_id).name }}'s
                        Task</template
                      ><template v-else>Consumer's Task</template>
                    </p>
                    <p title="Date Created" v-if="convertDate(task.timeline_task_created_datetime)">
                      <i class="fas fa-history"></i>&nbsp;{{
                        convertDate(task.timeline_task_created_datetime) || null
                      }}
                    </p>
                    <p
                      title="Expiration Date"
                      v-if="convertDate(task.timeline_task_expiration_datetime)"
                    >
                      <i
                        :class="
                          (task.autocomplete ? 'text-success' : 'text-danger') +
                            ' fas fa-stopwatch'
                        "
                      ></i
                      >&nbsp;{{
                        convertDate(task.timeline_task_expiration_datetime) ||
                          null
                      }}
                    </p>
                    <template v-if="task.completed">
                      <button
                        v-if="
                          task.input_meta &&
                            Object.keys(task.input_meta).length > 0
                        "
                        class="btn-outline-blue mt-1"
                        @click="displayCompletedTask(task.input_meta)"
                      >
                        View Completed Task
                      </button>
                      <span v-else>
                        Task Completed (No Input)
                      </span>
                    </template>
                    <template v-else-if="!task.completed && task.in_progress">
                      <button
                        v-if="
                          task.widget_meta &&
                            (typeof task.widget_meta == 'string' ||
                              (Array.isArray(task.widget_meta) &&
                                task.widget_meta.length > 0) ||
                              Object.keys(task.widget_meta).length > 0) &&
                            task.manager_id &&
                            task.timeline_task_group_task_id &&
                            (timeline.manager_id ==
                              task.timeline_task_manager_id ||
                              getUserIsAdministrator ||
                              getUserIsClientAdmin)
                        "
                        class="btn-blue mt-1"
                        @click="startTask(task)"
                      >
                        Complete Task
                      </button>
                      <button
                        v-if="task.timeline_task_manager_id"
                        class="timeline-btn btn btn-outline-secondary mt-2"
                        @click="notifyTaskID = task.timeline_task_group_task_id"
                        v-b-modal.contactManager
                      >
                        <i class="far fa-envelope"></i> Notify Manager
                      </button>
                    </template>
                    <template
                      v-else-if="
                        timeline.unsubscribed &&
                          !task.timeline_task_manager_id &&
                          task.in_progress
                      "
                    >
                      <span class="text-danger"
                        >Incomplete (Consumer Unsubscribed)</span
                      >
                    </template>
                    <template v-else-if="!task.completed && !task.skipped">
                      <template v-if="group.delay">
                        <span class="text-secondary">
                          Starts {{ convertDate(group.delay) }}
                        </span>
                      </template>
                      <template v-else-if="!task.timeline_task_created_datetime">
                        <span class="text-secondary"
                          >Waiting for previous tasks</span
                        >
                      </template>
                      <template
                        v-else-if="!task.timeline_task_expiration_datetime"
                      >
                        <span class="text-danger">Dropped</span>
                      </template>
                      <span v-else class="text-danger">Expired</span>
                    </template>
                  </b-card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <b-container v-else>
        <div v-if="displayTimelineTaskUUID">
          <router-view></router-view>
        </div>
        <div v-else-if="render && renderedTask != null">
          <div
            class="col mx-auto mb-3"
            v-for="(inputs, key) in renderedTask"
            :key="key"
          >
            <h5 class="col-6 text-right" v-if="typeof inputs === 'object'">
              Questionnaire: <i>{{ key }}</i>
            </h5>
            <h5 v-if="typeof inputs === 'string'">
              File Upload: <i>{{ key }}</i>
            </h5>
            <div>
              <template v-if="inputs">
                <template v-if="typeof inputs == 'object'">
                  <div class="container-fluid d-flex flex-column">
                    <div class="">
                      <div
                        class="row py-2"
                        v-for="entry in getObjectEntries(inputs, key)"
                        :key="entry[0]"
                      >
                        <span
                          class="col-12 col-md-6 col-lg-6 text-right"
                          v-if="entry[0]"
                        >
                          <strong>{{ entry[0] }}</strong>
                        </span>
                        <span
                          class="col-12 col-md-6 col-lg-6 text-left"
                          v-if="entry[1]"
                          >{{ entry[1].toString() }}</span
                        >
                        <span class="col-12 col-md-6 col-lg-6 text-left" v-else
                          >Complete</span
                        >
                      </div>
                    </div>
                  </div>
                </template>
                <template v-else-if="typeof inputs == 'string'">
                  <!-- lazy file type detection -->
                  <a
                    href="javascript:void(0)"
                    @click.once.prevent="fetchLink($event, inputs)"
                    >Download
                    {{
                      inputs.substr(inputs.lastIndexOf("/") + 1) || "File"
                    }}</a
                  >
                </template>
                <template v-else>
                  {{ inputs }}
                </template>
              </template>
              <template v-else>
                <span class="font-weight-bold">Empty Response</span>
              </template>
            </div>
          </div>
        </div>
      </b-container>
    </template>
  </ContentContainer>
</template>

<script>
import { mapGetters } from "vuex";
import draggable from "vuedraggable";
import moment from "moment";
import TimelineView from "../workflow/TimelineView";
import { Storage } from "aws-amplify";
import ContentContainer from "@/components/templates/ContentContainer";

export default {
  components: { TimelineView, ContentContainer },
  data() {
    return {
      loading: false,
      timeline: null,
      consumer: null,
      previewWidgets: null,
      notifyTaskID: null,
      message: null,
      displayTimelineTaskUUID: false,
      taskGroups: [],
      taskInContext: null,
      gdx: null,
      tdx: null,
      renderedTask: null,
      render: false,
    };
  },
  async created() {
    this.$bus.$on("submitInputMeta", async ($event) => {
      if ($event && this.getTimelineInContext && this.displayTimelineTaskUUID) {
        let inputMeta = {
          timelineID: this.getTimelineInContext.timeline_id,
          consumerID: this.getTimelineInContext.consumer_id,
          timelineTaskGroupTaskUUID: this.displayTimelineTaskUUID,
          inputMeta: $event,
          completed: true,
        };
        if (await this.$store.dispatch("updateInputMeta", inputMeta)) {
          await this.$store.dispatch(
            "createAlerts",
            "Your task has been completed!"
          );
          await this.setupTimelineObject(true);
          this.viewOverview();
        }
      }
    });
  },
  async mounted() {
    try {
      this.loading = true;
      const clientID = this.$route.params.clientID;
      const timelineID = this.$route.params.timelineID;

      await this.$store.dispatch("getClientByID", clientID);
      await this.$store.dispatch("getTimeline", timelineID);
      try {
        await this.$store.dispatch("getClientUsers");
      } catch (err) {
        // pass
      }

      await this.setupTimelineObject();
      this.consumer =
        (await this.$store.dispatch(
          "getConsumer",
          this.getTimelineInContext.consumer_id
        )) || null;

      await this.initBreadcrumbs();
      if (this.$attrs.taskUUID) {
        this.displayTimelineTaskUUID = this.$attrs.taskUUID;
      }
    } finally {
      this.loading = false;
    }
  },
  async beforeDestroy() {
    this.$bus.$off("submitInputMeta");
  },
  computed: {
    ...mapGetters([
      "getUser",
      "getTimelineInContext",
      "getUserIsAdministrator",
      "getUserIsClientAdmin",
      "getClientInContext",
      "getClientUserData",
    ]),
  },
  methods: {
    async initBreadcrumbs() {
      let displayName = `Consumer #${this.getTimelineInContext.consumer_id}`;
      if (this.consumer) {
        if (this.consumer.meta.firstName && this.consumer.meta.lastName) {
          displayName = `${this.consumer.meta.firstName} ${this.consumer.meta.lastName}`;
        } else {
          displayName = this.consumer.consumer_email;
        }
      }
      displayName += ` ${
        this.getTimelineInContext?.workflow?.workflow_name
          ? `'${this.getTimelineInContext?.workflow?.workflow_name}' `
          : ""
      }timeline`;
      await this.$bus.$emit("breadcrumbData", [
        {
          text: "Timelines",
          to: {
            name: "ClientTimelines",
          },
        },
        {
          text: displayName,
          to: {
            name: "ClientTimelineOverview",
            params: {
              timelineID: this.getTimelineInContext.timeline_id,
            },
          },
        },
      ]);
    },
    async setupTimelineObject(forceReload) {
      /** Helper function to be called when needed */
      let timelineID = this.$attrs.timelineID;
      if (forceReload || !timelineID) {
        timelineID = this.getTimelineInContext?.timeline_id || timelineID || 0;
      }
      if (
        timelineID &&
        (forceReload || this.getTimelineInContext?.timeline_id != timelineID)
      ) {
        await this.$store.dispatch("getTimeline", timelineID);
      }
      if (!this.getTimelineInContext) {
        this.$store.dispatch("createErrors", "Timeline was not found");
        this.routeBackToTimelines(true);
        return;
      }

      await this.getTimelineData();
    },
    async getTimelineData() {
      if (this.getTimelineInContext) {
        this.timeline = this.getTimelineInContext;
        let groups = await this.$store.dispatch("getWorkflowTaskGroups", {
          workflowID: this.timeline.workflow_id,
        });

        if (groups) {
          this.taskGroups = groups.task_groups;

          for (let i in this.taskGroups) {
            const taskGroup = this.taskGroups[i];

            const prevTaskGroup = this.taskGroups[i - 1];
            let prevCompleted = false;
            if (prevTaskGroup) {
              prevCompleted = this.taskGroups[i - 1].tasks.reduce((a, b) => a && b.completed, true);
            }

            // bind task groups to the max delay of the tasks inside it
            const maxDelay = Math.max(0, ...taskGroup.tasks.filter((task) => this.timeline.timeline_task_delays[task.task_name]).map((task) => new Date(this.timeline.timeline_task_delays[task.task_name])));            
            if (maxDelay && prevCompleted) {
              this.taskGroups[i].delay = maxDelay;
            }

            this.taskGroups[i].tasks = this.taskGroups[i].tasks.map((item) => {
              const newItem = { ...item };

              this.timeline.timeline_task_groups.forEach((group) => {
                group.timeline_task_group_tasks.forEach((timelineTask) => {
                  if (timelineTask.task_id == item.task_id) {
                      newItem.widget_meta = timelineTask.widget_meta;
                      newItem.input_meta = timelineTask.input_meta;
                      newItem.completed = timelineTask.completed;
                      newItem.in_progress = timelineTask.in_progress;
                      newItem.timeline_task_group_task_id = timelineTask.timeline_task_group_task_id;
                      newItem.timeline_task_group_task_uuid = timelineTask.timeline_task_group_task_uuid;
                      newItem.timeline_task_manager_id = timelineTask.manager_id;
                      newItem.timeline_task_created_datetime = timelineTask.created_datetime;
                      newItem.timeline_task_expiration_datetime = timelineTask.expiration_datetime;
                  }
                });
              });

              return newItem;
            });
          }
        }
      }
    },
    async resetTimeline() {
      try {
        this.loading = true;
        let result = await this.$store.dispatch("updateTimeline", {
          actionID: 1, // Note: Action ID can be any valid prior action ID > 0, but this is a full reset.
          timelineID: this.timeline.timeline_id,
        });
        if (!result || !result.success) {
          // non-400 error
          return this.$store.dispatch(
            "createErrors",
            result?.message || "There was an error resetting this timeline"
          );
        } else {
          this.$store.dispatch("createAlerts", "This timeline has been reset.");
          await this.setupTimelineObject(true);
        }
      } finally {
        this.loading = false;
      }
    },
    async resumeTimeline() {
      try {
        this.loading = true;

        let result = await this.$store.dispatch("updateTimeline", {
          actionID: this.timeline.current_action_id,
          timelineID: this.timeline.timeline_id,
        });
        if (!result || !result.success) {
          // non-400 error
          return this.$store.dispatch(
            "createErrors",
            result?.message || "There was an error resuming this timeline"
          );
        } else {
          this.$store.dispatch("createAlerts", "This timeline has been resumed.");
          await this.setupTimelineObject(true);
        }
      } finally {
        this.loading = false;
      }
    },
    async removeConsumer() {
      try {
        this.loading = true;
        let result = await this.$store.dispatch("updateTimeline", {
          unsubscribed: true,
          timelineID: this.timeline.timeline_id,
        });
        if (!result || !result.success) {
          // non-400 error
          return this.$store.dispatch(
            "createErrors",
            result?.message ||
              "There was an error removing the consumer from this timeline"
          );
        } else {
          this.$store.dispatch(
            "createAlerts",
            "The consumer has been removed from this timeline."
          );
          await this.setupTimelineObject(true);
        }
      } finally {
        this.loading = false;
      }
    },
    async abortTimeline() {
      try {
        this.loading = true;
        let result = await this.$store.dispatch("updateTimeline", {
          inProgress: false,
          timelineID: this.timeline.timeline_id,
        });
        if (!result || !result.success) {
          return this.$store.dispatch(
            "createErrors",
            result?.message || "There was an error aborting this timeline"
          );
        } else {
          this.$store.dispatch(
            "createAlerts",
            "This timeline has been aborted."
          );
          await this.setupTimelineObject(true);
        }
      } finally {
        this.loading = false;
      }
    },
    async notifyManager() {
      // notify manager of incomplete task
      if (!this.notifyTaskID) {
        return null;
      }
      let res = await this.$store.dispatch("timelineNotifyManager", {
        timelineID: this.timeline.timeline_id,
        timelineTaskGroupTaskID: this.notifyTaskID,
        consumerID: this.timeline.consumer_id,
      });
      this.$store.dispatch(
        "createAlerts",
        "Your message has been sent. Your manager will be in contact within 48 hours."
      );
      this.notifyTaskID = null;
    },
    convertDate(date) {
      if (!date) {
        // if no date, do not use.
        return null;
      }
      let momentObj = moment(date);
      if (!momentObj || !momentObj.isValid()) {
        return null;
      }
      return momentObj.format("MMMM DD, YYYY h:mm A");
    },
    async startTask(task) {
      this.displayTimelineTaskUUID = task.timeline_task_group_task_uuid;
      await this.$router
        .push({
          name: "ManagerTimelineTask",
          params: {
            timelineID: this.getTimelineInContext.timeline_id,
            taskUUID: task.timeline_task_group_task_uuid,
          },
        })
        .catch(() => {});
    },
    displayCompletedTask(task) {
      this.render = true;
      this.renderedTask = task;
    },
    getObjectEntries(obj, objTitle) {
      try {
        let widget = null;

        this.taskGroups.forEach((taskGroup) => {
          taskGroup.tasks.forEach((task) => {
            task.widget_meta.forEach((widgetMeta) => {
              if (
                widgetMeta.widget.type === "Questionnaire" &&
                widgetMeta.widget.title === objTitle
              ) {
                widget = widgetMeta.widget;
              }
            });
          });
        });

        const questions = widget.data.content;

        const getQuestionIndex = (objKey) => {
          return questions.findIndex((question) => question.label === objKey);
        };

        return Object.entries(obj).sort(
          (a, b) => getQuestionIndex(a[0]) - getQuestionIndex(b[0])
        );
      } catch (err) {
        return Object.entries(obj);
      }
    },
    async viewOverview() {
      if (this.displayTimelineTaskUUID) {
        this.$router
          .push({
            name: "ClientTimelineOverview",
            params: {
              clientID: this.getClientInContext.client_id,
              timelineID: this.getTimelineInContext.timeline_id,
            },
          })
          .catch(() => {});
      }
      await this.initBreadcrumbs();
      [this.displayTimelineTaskUUID, this.render] = [false, false];
      if (this.renderedTask != null) {
        this.renderedTask = null;
      }
    },
    async routeBackToTimelines(replace) {
      if (!replace) {
        this.$router
          .push({
            name: "ClientTimelines",
            params: { clientID: this.getClientInContext.client_id },
          })
          .catch(() => {});
      } else {
        this.$router
          .replace({
            name: "ClientTimelines",
            params: { clientID: this.getClientInContext.client_id },
          })
          .catch(() => {});
      }
    },
    toConsumer() {
      this.$router
        .push({
          name: "ClientConsumers",
          params: {
            clientID: this.getClientInContext.client_id,
            actionID: this.consumer.consumer_id,
          },
        })
        .catch(() => {});
    },
    async fetchLink(event, fileURL) {
      try {
        // has not been updated yet.
        // validate and lazy check if person is legally allowed to download things.
        let url = new URL(fileURL);
        // decode uri parts of pathname -- this will be encoded by passing in to new URL
        let key = decodeURIComponent(url.pathname);
        if (key.startsWith("/")) {
          key = key.substr(1);
        }
        let [
          clientIDStr,
          ignore,
          consumerIDStr,
          timelineIDStr,
          timelineTaskUUID,
          filename,
        ] = key.split("/");
        let clientID = parseInt(clientIDStr, 10);
        let consumerID = parseInt(consumerIDStr, 10);
        let timelineID = parseInt(timelineIDStr, 10);
        if (
          !clientID ||
          clientID != this.getClientInContext.client_id ||
          !consumerID ||
          !timelineID ||
          !timelineTaskUUID ||
          !filename
        ) {
          // make sure this url is valid and pertains ONLY to what we're doing.
          throw new Error("");
        }
        const signedURL = await Storage.get(key);

        // simulate having clicked the actual link -- this lets us open it in a new window.
        event.target.href = signedURL;
        event.target.target = "_blank";
        event.target.rel = "noopener noreferrer";
        event.target.download = filename || "downloaded_file";
        setTimeout(function() {
          event.target.click();
        }, 300);
      } catch (e) {
        alert("There was an error fetching this file. Please contact support");
      }
      return false;
    },
    getManager(managerId) {
      return (
        this.getClientUserData.find(
          (clientUser) => clientUser.manager_id === managerId
        ) ?? {}
      );
    },
  },
};
</script>
